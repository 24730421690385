<template>
<div>
  <autogestion-Paso-Uno class="landscape"></autogestion-Paso-Uno>
  <v-row>
    <v-col cols="12" style="height: 100px;"></v-col>
  </v-row>
  <v-container style="margin-top:75px">
      <v-row align="center"> 
        <v-col md="12">
          <h3 class="ml-1 mb-n2 colorCustom2--text">Ingrese DNI</h3>
        </v-col>
        <v-col md="12">
          <v-text-field    
            :value="dataForm.dni"   
            class="ml-auto"
            solo
            hide-details
            type="number"
            data-layout="normal"
            autocomplete="off"
            @input="onInputChange"
            ref="pileta"
          />     
          </v-col>  
          <v-col md="12">
            <SimpleKeyboard @onChange="onChange" :input="dataForm.dni" :layout="$store.getters.getLayoutNumeric" :buttonTheme="$store.getters.getButtonThemeNumeric"/>
          </v-col>
        </v-row>             
        <v-row justify="center">
          <v-col md="8">
            <v-row>
              <v-col style="text-align:center">
                <botonVolver />
              </v-col>
              <v-col style="text-align:center">
                <botonBuscar @Click="Buscar" />
              </v-col>  
            </v-row>
          </v-col>
        </v-row>  
  </v-container>
  <mensajeCerrarDialog ref="mensajeCerrarDialog"/>
  <mensajeErrorDialog ref="mensajeErrorDialog"/>

</div>
</template>
<script>
import SimpleKeyboard from '../components/teclados/KeyBoard.vue' 
import '../components/teclados/dark-theme.css'
  export default {
    components: {
      SimpleKeyboard,
      autogestionPasoUno: () => import('../components/autogestionPasoUno.vue'),
    },
    data () {
      return {
        dataForm: {
          dni: '',
        },
      }
    },
    mounted () {
      this.$refs.pileta.focus()   
    },         
    methods: {
      onChange(input) {
        this.dataForm.dni = input;
      },
      onInputChange(input) {
        this.dataForm.dni = input;
      },        
      Buscar: async function () {    
        let error = false
        var existe = false     
        await this.$store.dispatch('axiosMod/getData', { url: '/SAT_WS/rest/cuentas/existe/' + this.dataForm.dni + '/PILETAS?td=DNI' }).then((res) => { 
          existe = res.existe
          if (!existe) {
            this.$refs.mensajeCerrarDialog.open(this.$store.getters.getMensajes["noDni"])      
          }          
        }).catch(() => {
          error = true
          this.$refs.mensajeErrorDialog.open(this.$store.getters.getMensajes["error"])
        })    

        if (existe && !error) {                    
          this.$router.push({ name: 'cuotasPileta', params: { tributo: 'PILETA', cuenta:this.dataForm.dni }})
        }       

      },     
    }
  }
</script>